import React from "react"
import { Link } from "react-router-dom"
import { DashboardBody } from "../styles"

const AboutMentalSkills = () => (
  <DashboardBody>
    <div className="container dashboard py-5">
      <div className="d-flex align-items-center my-4">
        <Link className="btn btn-outline-main" to="/mental-skills-dashboard">Back</Link>
      </div>
      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <div className="pre-text">MSC</div>
              <h1 className="mb-4">What is MSC?</h1>
              <p>The Mental Skills Calculator (MSC) is an evidence-based online assessment designed by the Performance Intelligence Research Institute that measures both behaviours and perceptions specific to mindset, cognitive function and decision making under pressure.</p>
              <p>Questions specific to key biopsychosocial markers (the interconnection between biology, psychology and socioenvironmental factors) help quantify current levels of resilience, decision making capacity, ability to sustain energy levels, psychological performance and the ability to lead through constant change and ambiguity.</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-xl-9">
              <h4>Our Philosophy</h4>
              <p>Traditional organisations and teams exist within a deficit model driven by a reactive approach to the more intangible, yet critical elements of high performance - leadership, culture, wellbeing, mental performance, connection and alignment. Our approach focuses on a skills-based model built upon fundamentals of sport/performance psychology, behaviour change and team dynamics.</p>
              <p>In high pressure environments including sport, military and the performing arts, it is widely recognised there are three things you can train*:</p>
              <ol>
                <li>Your craft (skills specific to occupation)</li>
                <li>Your body (strength and conditioning, recovery, nutrition, etc)</li>
                <li>Your mind (mental skills)</li>
              </ol>
              <p>Psychological skills, or mental skills, are tools for the mind. Just like putting a fitness program together to train the body to be fit, fast, flexible and strong; we can do exactly the same for your brain making it adaptive, strategic, and creative. Neuroscience teaches us training the mind is like learning a new skill, such as playing the guitar or ice skating: we need to keep practising and have the right coaching and support until we become competent, gain mastery and can transfer our skills to new situations. Or in simple language, it’s about designing a training program to follow then doing the reps and sets.</p>
              <p>The biggest differentiator between great performers and okay performers is the ability to manage stress and adapt to changing demands. When we work with athletes, high performers, teams or entire organisations; the primary goal of a mental skills training program is to front-load cognitive skills so that they can then be drawn upon when needed at any time and especially under high levels of pressure.</p>
              <small>*Michael Gervais, Sport Psychologist.</small>
            </div>
          </div>

        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Training Mental Skills</h2>
              <p>Performance psychology traditionally focused on elite sports, performing arts and the military. Now widely available research by psychologist Sonja Lyubomirsky and her colleagues, illustrates that approximately 50% of the difference in our psychological framework is determined by genetics, 10% by current lifestyle circumstances, and the remaining 40% by actions, attitude, learned optimism and the way we handle situations. This means that we have a large scope to train and to change the way we think and respond under pressure.</p>
              <p>Training mental skills is not about &apos;fixing&apos; perceived performance-related issues or problems. Our approach targets skill development and behaviour change, focusing on individual needs and providing a toolbox of skills to access in a variety of performance contexts. Shifting from a problem-focus, to more of a solutions-focus allows individuals to embrace pressure and perform consistently when it matters.</p>
              <p>Two key factors underpinning successful mental skills programs are integrating and normalising the language across the organisation. What we are striving for is a healthy, positive, adaptive mindset with the ability to commit to high pressure Performance Moments and to be open to the growth and learning that new challenges offer.</p>

              <div className="mt-5">
                <h4>12 Essential Mental Skills</h4>
                <p>Andrew May and leading neuropsychologist Dr Nicola Gates have identified 12 Essential Mental Skills and three levels of development that underpin the approach in our Mental Skills Academy (an evidence-based live, hybrid or virtual mental skills training program). The three levels include:</p>
              </div>

              <div className="row mt-5">
                <div className="col-xl-4">
                  <strong>Level 1 Self-Discovery</strong>
                  <p>Includes self-talk, personal identity and the ability to manage physical and psychological energy. This level focuses on self-awareness and establishing a foundation to build upon.</p>
                </div>
                <div className="col-xl-4">
                  <strong>Level 2 Foundation Skills</strong>
                  <p>
                    Are &apos;entry level performance psychology techniques&apos; that help frontload the mind to perform consistently under pressure. If you haven&apos;t trained your body to relax using diaphragmatic breathing or trained your mind to be present, manage emotions and be calm under pressure, it&apos;s not going to happen when you need it most. Communicating effectively and developing meaningful relationships is another important skill in this level.
                  </p>
                </div>
                <div className="col-xl-4">
                  <strong>Level 3 Performance Psychology</strong>
                  <p>Incorporates advanced techniques that train the brain to make clear decisions, perform under pressure and self-regulate behaviour. This includes pre-performance routines, optimism, focus, mental imagery, drive and confidence. The problem many people and teams make however, is they dive into the pointy end first, before building a solid foundation.</p>
                </div>
              </div>

              <div className="row mt-5">
                <img src="/assets/MSC-12-metal-skills-5775325380ece7255b88eab58aaaa608c68bd87355c06192996d45ae3cc12a21.svg" alt="12 Essential Mental Skills chart" width="90%" />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-5">Understanding Your Results</h2>
              <h4>Your overall Mental Skills Calculator Score</h4>

              <p>The Mental Skills Calculator (MSC) score is calculated out of 100 and the scores defined in the following categories:</p>
              <li>85 and above - Flourishing</li>
              <li>Between 65-85 - Striving</li>
              <li>Between 50-65 - Surviving</li>
              <li>Less than 50 - Languishing</li>

              <p>As you complete more than one MSC assessment, a trend of your recent MSC scores is shown on your calculator dashboard.</p>

              <div className="mt-5">
                <h4>Breakdown of Mental Skills</h4>
                <p>The Mental Skills Calculator is broken down into three main categories:</p>
                <ol>
                  <li>
                    <b>Self-Discovery</b>
                    {" "}
                    - consists of skills involving managing your inner voice and cultivating meaning, understanding who you are, and utilising and replenishing physical energy and psychological energy.
                  </li>
                  <li>
                    <b>Foundations Skills</b>
                    {" "}
                    - consists of slowing your breathing rate to relax the body and calm mind, being fully present and engaged in the moment, and learning to control nerves & making clear decisions, and having meaning for connection.
                  </li>
                  <li>
                    <b>Performance Psychology</b>
                    {" "}
                    - consists of developing rituals and routines to support best performance, believing the future will be okay, managing distractions and paying attention for best outcome, using sensory information to visualise a future outcome, setting a personal vision and achieving goals and trusting yourself to manage in adversity.
                  </li>
                </ol>
                <p>In each category you also answered a question which gives an indication of how effective you thought your skills were in that area. If your Effectiveness Score is lower than your Skill Score, this may indicate that you view your effectiveness in that category as less than your behaviours would indicate. The opposite indicates that you may overestimate your effectiveness compared to your behaviours.</p>
                <p>Both your Effectiveness Score and Skill Score are shown on your calculator dashboard.</p>
                <p>A further breakdown and explanation of your scores can be viewed by clicking on the “&gt;” icon next to each category.</p>
              </div>
              <div className="mt-5">
                <h4>Your Recommendations</h4>
                <p>A set of recommendations is tailored for you based on your overall MSC score and your three lowest scoring skill areas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Accessing Your Latest Report</h2>
              <p>You can download a PDF report of your latest MSC assessment at any time by clicking on the “Download Latest Report” button. This report contains your latest results, information on MSC and your recommendations in one easy-to-read document. You are also sent a copy of this report via email each time you complete an assessment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)

export default AboutMentalSkills
