import React, { useState } from "react"
import dayjs from "dayjs"

const GeneralInfoSection = ({
  currentSection,
  form,
  onChangeHandler,
  questionSet,
  setCurrentSection,
  title,
  calculatorName
}) => {
  const [showWarning, setShowWarning] = useState(false)
  const { questions } = questionSet
  const currentBirthday = _.get(form, `${questions[0].question_slug}.free_text_field`)
  const age = dayjs().diff(currentBirthday, "year")
  const italicisedDescription = "Why do we ask for date of birth and sex?"
  const description = `The ${calculatorName || "MatchFit Calculator"} is based on the best available science-based research. Reporting your age and sex allows us to determine your biological age, as we compare your assessment responses to age and sex-matched population norms reported in epidemiological research. Your information remains confidential and no identifiable data linking you to your age or sex will be shared. Inclusivity and diversity are important to Performance Intelligence, in fact they form part of our company values. Our vision is to adapt techniques as science-based research evolves to support greater diversity.`
  const maxBirthday = dayjs().subtract(18, "year").format("YYYY-MM-DD")

  const onNextStep = () => {
    if (age < 18) {
      setShowWarning(true)
    } else {
      setCurrentSection(currentSection + 1)
    }
  }

  if (currentSection !== questionSet.page_index) return null
  return (
    <>
      <h1>{title}</h1>
      <hr />
      {/* TODO: fix q-group styling on this page */}
      {/* <div className="q-group"> */}
      <div className="col-8 col-sm-5">
        <p>{questions[0].label}</p>
        <input
          className="form-control"
          id={questions[0].answers[0].id}
          name={questions[0].question_slug}
          max={maxBirthday}
          onChange={onChangeHandler}
          pattern="/d+"
          step="1"
          type="date"
          value={currentBirthday || ""} />
        {showWarning && (<p className="mt-3 text-danger">Please enter an age of 18 or older.</p>)}
      </div>

      <div className="col-6 col-sm-5">
        <p className="mt-5">{questions[1].label}</p>
        <select
          className="form-control"
          id={questions[1].answers[0].id}
          name={questions[1].question_slug}
          onChange={onChangeHandler}
          value={_.get(form, `${questions[1].question_slug}.free_text_field`) || ""}>
          <option disabled value="">Select</option>
          <option value="female">Female</option>
          <option value="male">Male</option>
          {calculatorName === "LiveLife Score" && (
            <option value="non_binary">Non-Binary</option>
          )}
        </select>
      </div>
      {/* </div> */}
      <div className="q-group">
        <div className="col-12 mt-5">
          <i>{italicisedDescription}</i>
          <p className="mt-2">{description}</p>
        </div>
      </div>
      <div className="bottom-cta d-flex justify-content-between">
        <span />
        <button
          className="btn btn-main"
          type="button"
          disabled={_.difference(["birthday", "sex"], Object.keys(form)).length > 0}
          onClick={onNextStep}>
          Next
        </button>
      </div>
    </>
  )
}

export default GeneralInfoSection