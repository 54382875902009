/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "react-router-dom"
import { OVERVIEW_PAGE_CONTENT } from "./constants"
import { DashboardBody } from "./styles"

const DashboardEmptyState = ({ unfinishedSurveyExists }) => (
  <DashboardBody>
    <div className="container dashboard" id="dashboard-10dwc">
      <div className="d-flex align-items-center justify-content-between my-4">
        <img src={OVERVIEW_PAGE_CONTENT["10DWC"].logoSrc} />
      </div>
      <div className="row text-center justify-content-center">
        <div className="col-lg-8">
          <h1 className="mb-4">
            Welcome to your 10 Dimensions of Wellbeing
          </h1>
          <p>10DWC is a science-based assessment designed by the Performance Intelligence Research Institute that brings together over 100 research-proven risk factors and has been adopted by leading organisations to assess and implement best-practice workplace wellbeing and performance programs.</p>
          <Link className="btn btn-link" to="/about-10dwc">What is 10DWC?</Link>
          <div className="text-center mt-5">
            <Link className="btn btn-main" to="/survey/intro">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)
export default DashboardEmptyState
