import React from "react"

export const ALLOWED_CALCULATOR_TYPES = ["10DWC", "matchfit", "mental_skills", "pulse_check", "burnout_proof", "live_life"]

export const WELLBEING_TYPES = [
  "physical",
  "social",
  "digital",
  "intellectual",
  "occupational",
  "spiritual",
  "cultural",
  "psychological",
  "financial",
  "environmental",
  "global"
]

export const DASHBOARD_RECOMMENDATIONS = [
  {
    heading: "Physical Wellbeing",
    key: WELLBEING_TYPES[0],
    content: [
      "Walk 10,000 steps each day (or equivalent energy expenditure doing other activities)",
      "Engage in cardiovascular exercise 2 to 3 times each week and resistance training 2 times each week",
      "Get 7 to 8 hours of restorative sleep most nights",
      "Achieve 3 to 4 alcohol free days (AFD's) each week",
      "For main meals choose fresh foods in their natural state, rather than packaged foods"
    ]
  },
  {
    heading: "Social Wellbeing",
    key: WELLBEING_TYPES[1],
    content: [
      "Organise to spend quality time with family and/or friends at least once a week (live or virtual)",
      "Regularly prioritise close relationships over work and other interests (put it in your diary)",
      "Listen more. Practice the art of listening to be a better communicator",
      "Add play, laughter and fun to your life. Start with activities you love doing",
      "Connect and engage with your local community"
    ]
  },
  {
    heading: "Digital Wellbeing",
    key: WELLBEING_TYPES[2],
    content: [
      "Turn off all alerts, notifications, and pop-up messages on digital devices (mobile, tablets, computers)",
      "Turn notifications off on your watch (constantly receiving messages fragments attention)",
      "Avoid using digital devices for at least 30 minutes each night before going to bed",
      "Dedicate a few hours each week to go tech-free and focus on Deep Work or engaging in activities you enjoy",
      "Go tech-free for a day on the weekend or on a day off work (ditch your mobile for most of the day)"
    ]
  },
  {
    heading: "Intellectual Wellbeing",
    key: WELLBEING_TYPES[3],
    content: [
      "Challenge yourself by playing a musical instrument, learning a language or other new skills",
      "Get excited about doing new things and exploring new experiences",
      "Enrol in an online course or attend a workshop that expands your thinking",
      "Purposely engage with other people to learn new things from them",
      "Be curious and open to new opportunities to learn and grow as a person"
    ]
  },
  {
    heading: "Occupational Wellbeing",
    key: WELLBEING_TYPES[4],
    content: [
      "Seek opportunities to express personal abilities and strengths in your current job",
      "Keep learning and developing new skills to future proof your career and help you progress",
      "Supplement in other parts of your life if you are not receiving meaning and purpose from work",
      "Understand that your role identity is not your personal identity. You are much more than just your job title",
      "Recognise and take action when work wears you down and starts to affect the rest of your life"
    ]
  },
  {
    heading: "Spiritual Wellbeing",
    key: WELLBEING_TYPES[5],
    content: [
      "Take time out for yourself and embrace silence and solitude",
      "Regularly engage in self-reflection (journal writing is a proven way of supporting this)",
      "Spend time each week doing activities that give you peace and harmony",
      "Focus on areas in your personal and professional life that give you meaning and connection",
      "Clearly define your personal purpose and understand your 'inner why'"
    ]
  },
  {
    heading: "Cultural Wellbeing",
    key: WELLBEING_TYPES[6],
    content: [
      "Express pride in your cultural background and spend time exploring your heritage",
      "Take time to appreciate and explore your cultural surroundings",
      "Seek diverse perspectives, be curious and open to learning from other cultures",
      "Speak up and respectively voice your opinions, even when they are different to others",
      "Explore ways to add more diversity to your life including friendships, learning and community activities"
    ]
  },
  {
    heading: "Psychological Wellbeing",
    key: WELLBEING_TYPES[7],
    content: [
      "Approach training your brain (mental skills) the same way you approach training your body",
      "Work on strategies to help you concentrate and focus",
      "Add fun, laughter, play and joy to your life (ideally 15 to 20 minutes every day)",
      "Practice gratitude every day and appreciate the good things you have in your life",
      "Reach out and seek support from other people when you are under high levels of stress and not coping"
    ]
  },
  {
    heading: "Financial Wellbeing",
    key: WELLBEING_TYPES[8],
    content: [
      "Set a weekly/monthly budget and spend less than you earn",
      "Make reducing debt a priority",
      "Be disciplined about saving a percentage of your wage each month",
      "Learn more about compound interest and financial planning to create future wealth",
      "Seek professional help to create a budget that works for you, especially If you feel stressed about finances"
    ]
  },
  {
    heading: "Environmental Wellbeing",
    key: WELLBEING_TYPES[9],
    content: [
      "Actively use nature and green spaces to enhance wellbeing (aim for 30 mins in nature each day)",
      "Intentionally spend time in places that make you feel happy, peaceful, and calm",
      "Use nature and the outdoors to regularly stimulate your senses (sight, sound, smell, taste, and touch)",
      "Invest the time to make your home feels inviting, comfortable and visually pleasing",
      "Set up your workplace (office, home office) to be ergonomic, functional, and visually pleasing"
    ]
  }
]
// TODO: fix up this const...

export const WELLBEING_CARD_DESC = {
  physical: "Physical Wellbeing is the ability to maintain a healthy quality of life that allows us to get the most out of daily activities without undue fatigue or physical stress.",
  social: "Social Wellbeing is being satisfied with relationships, performing in social roles and feeling a sense of belonging to a group or tribe.",
  digital: "Digital Wellbeing is the ability to craft and maintain a healthy relationship with technology.",
  intellectual: "Intellectual Wellbeing is the ability to be open to new ideas, think critically, a willingness to learn new skills, technical and non-technical.",
  occupational: "Occupational Wellbeing is being intellectually stimulated with career fulfilment and the experience of satisfaction and pleasure in our employment.",
  spiritual: "Spiritual Wellbeing is being connected to oneself and the natural world with meaning and understanding",
  cultural: "Cultural Wellbeing is being intellectually stimulated with career fulfilment and the experience of satisfaction and pleasure in our employment.",
  psychological: "Psychological Wellbeing is our ability to function well in the world and manage thoughts, feelings and emotions. Relates to sense of purpose and autonomy, personal mastery and the ability to grow and develop.",
  financial: "Financial Wellbeing is a sense of security and feeling as though you have enough money to meet your needs, are in control of day-to-day finances and have the financial freedom to make choices that allow you to enjoy life.",
  environmental: "Environmental Wellbeing is being adjusted to a pleasant and stimulating work and home environment and deriving pleasure and stimulation from natural surroundings.",
  global: "The Global Wellbeing Scale is measured as a cross-check set of questions, with one question for each of the ten dimensions."
}

export const getWellbeingOverallDesc = displayScore => {
  if (displayScore <= 50) {
    return (
      <div>
        Your wellbeing score fell within the
        {" "}
        <span className="category">Languishing</span>
        {" "}
        category. Your priority is to improve current wellbeing scores by implementing proven wellbeing behaviours across all 10 Dimensions and connecting with others to help you develop healthier wellbeing habits.
      </div>
    )
  }

  if (displayScore <= 65) {
     return (
       <div>
         Your wellbeing score fell within the
         {" "}
         <span className="category">Surviving</span>
         {" "}
         category and shows that your wellbeing behaviours and perceptions have scope to improve. Focus on modifying behaviours in the dimensions where you scored lowest to move up into the Striving category.
       </div>
     )
  }

  if (displayScore < 85) {
    return (
      <div>
        Your wellbeing score fell within the
        {" "}
        <span className="category">Striving</span>
        {" "}
        category. Your behaviours and perceptions are mostly positive, and you are striving towards optimising wellbeing. Focus on modifying behaviours in the dimensions where you score lowest to move to the Flourishing category.
      </div>
    )
  }

  return (
    <div>
      Congratulations. Your wellbeing score fell within the
      {" "}
      <span className="category">Flourishing</span>
      {" "}
      category and shows positive functioning across all 10 Dimensions with high frequency of behaviours and very high perceptions of wellbeing. Focus on sustaining your current wellbeing habits.
    </div>
)
}

export const getWellbeingScore = displayScore => {
  if (displayScore <= 50) {
    return "Languishing"
  }

  if (displayScore <= 65) {
    return "Surviving"
  }

  if (displayScore < 85) {
    return "Striving"
  }

  return "Flourishing"
}

export const OVERVIEW_PAGE_CONTENT = {
  "10DWC": {
    header: "10 Dimensions of Wellbeing Calculator",
    description: "An assessment that brings over one hundred research-proven factors to measure your holistic wellbeing",
    logoSrc: "/assets/10dwc-badge-48eba242e56ab6656525251adf29ad2158598ec399f1a95cb53491bed58e107f.svg",
    dashboardHref: "/ten-dwc-dashboard",
    surveyIntroHref: "/survey/intro",
  },
  pulse_check: {
    header: "Pulse Check",
    description: "A short weekly pulse check to track your progress",
    logoSrc: "/assets/pulse-badge-6e5eb22f0fab1f8caf874aec5b89e13bb37547dfd5535cd1eae95d077b15f851.svg",
    dashboardHref: "/pulse-check-dashboard",
    surveyIntroHref: "pulse-check/questions",
  },
  matchfit: {
    header: "MatchFit Calculator",
    description: "A comprehensive assessment of physical and psychological wellbeing. Being MatchFit is the first stage of improving Performance Intelligence and realising your full potential.",
    logoSrc: "/assets/matchfit-badge-94717e082653f689683b35ba04974d497cb0f150a20e0236a0e620ae87cc8897.svg",
    textSrc: "/assets/matchfit-logo-text-d5d1eff6936a971c777cadfa828c9bc822d1b646443bd6f150ecac1886ee6f3a.svg",
    dashboardHref: "/matchfit-dashboard",
    surveyIntroHref: "matchfit/intro",
  },
  mental_skills: {
    header: "Mental Skills Calculator",
    description: "An assessment of mindset, cognitive function and the ability to perform under high pressure.",
    logoSrc: "/assets/msc-badge-a0e9eee532bb2c44ba0dfdb28df88353a1050b8f6063c1aee8ce579b28428d9a.svg",
    dashboardHref: "/mental-skills-dashboard",
    surveyIntroHref: "mental-skills/intro",
  },
  burnout_proof: {
    header: "Burnout Proof Calculator",
    description: "An assessment capturing common burnout symptoms and evaluating the future risk of burnout.",
    logoSrc: "/assets/bpc-badge-dfdd1ca8f1bf4bb6aacefe82706e824f99032a2d495b5a76498623698d72f17c.svg",
    textSrc: "/assets/bpc-logo-text-4cf4e25f1b9de4588cc02f902d487813bdb48ca868da13ed11a84528e6cbc2cf.svg",
    dashboardHref: "/burnout-proof-dashboard",
    surveyIntroHref: "burnout-proof/intro",
  },
  live_life: {
    header: "LiveLife Score",
    description: "An evidence-based physical and psychological wellbeing metric, incorporating both Biological Age and a Mental Fitness Gauge.",
    logoSrc: "/assets/lls-badge-8820727e137b15bee89fd5579fe26ec5e9545a4201ef32fa6ff1fbc3c175e5d8.svg",
    textSrc: "/assets/lls-logo-text-2cddf3c35018590c70b5aee9a86c2bf8b974c900c00a1ab8f8adc48eb21a0b73.svg",
    dashboardHref: "/livelife-dashboard",
    surveyIntroHref: "livelife/intro",
  }
}